import {faArrowLeft} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {Button} from '@mui/joy';
import PropTypes from 'prop-types';
import {useTranslation} from 'react-i18next';
import {useDispatch} from 'react-redux';
import {routerActions} from '../../../lib/router/connected-router-saga/connected-router.actions';

export const BackButton = ({onBackClick, fallbackRoute}) => {
    const dispatch = useDispatch();
    const {t} = useTranslation('vatDeclaration');
    const handleClick = () => {
        if (onBackClick) {
            onBackClick();
        } else if (window.history.length > 1) {
            dispatch(routerActions.back());
        } else {
            dispatch(routerActions.push(fallbackRoute));
        }
    };

    return (
        <Button
            variant="plain"
            color="primary"
            size="sm"
            startDecorator={<FontAwesomeIcon icon={faArrowLeft} />}
            sx={{'color': 'linen.500', '--variant-plainHoverBg': 'transparent'}}
            onClick={handleClick}
        >
            {t('back')}
        </Button>
    );
};

BackButton.propTypes = {
    onBackClick: PropTypes.func,
    fallbackRoute: PropTypes.string.isRequired,
};

BackButton.defaultProps = {
    onBackClick: undefined,
};
