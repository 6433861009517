import {faBuildingColumns} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {Box, CircularProgress, Stack, Typography} from '@mui/joy';
import {TabContext, TabPanel} from '@mui/lab';
import {ThemeProvider} from '@mui/material';
import PropTypes from 'prop-types';
import React, {useEffect, useMemo, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';
import BankSidebar from './BankSidebar';
import CategorisationPage from './categorisation/components/CategorisationPage';
import AccountsHeader from './transaction-list/AccountsHeader';
import {AddButtons} from './transaction-list/AddButtons';
import CombinedTransactionList from './transaction-list/CombinedTransactionList';
import FiltersMobileWrapper from './transaction-list/FiltersMobileWrapper';
import HiwayTransactionList from './transaction-list/HiwayTransactionList';
import TransactionList from './TransactionList';
import {ReactComponent as HiText} from '../../../../../assets/svg/logo/hi.svg';
import withJoyUI from '../../../../../components/joy-ui/joyui-theme-provider/JoyUiThemePRoviderWrapper';
import Fade from '../../../../../components/joy-ui/mui-legacy/Fade';
import Section from '../../../../../components/joy-ui/sections/Section';
import {useIsMobileSize} from '../../../../../hooks/use-is-mobile-size';
import {selectRouterLocation} from '../../../../../lib/router/connected-router-saga';
import {theme} from '../../../../../lib/theme';
import {isUserAdmin, userDefaultColor} from '../../../../../utils/user-roles';
import {LoadingSelectors, LoadingTypes, useLoadingState} from '../../../../loading';
import {LoggedInUserSelectors} from '../../../../user/modules/logged-in-user';
import ArchiveIntegrationModal from '../../../components/archive/ArchiveIntegrationModal';
import CloseHiwayIntegrationModal from '../../../components/archive/CloseHiwayIntegrationModal';
import CloseHiwayIntegrationUnavailableModal from '../../../components/archive/CloseHiwayIntegrationUnavailableModal';
import DeleteHiwayIntegrationModal from '../../../components/archive/DeleteHiwayModal';
import DeleteIntegrationModal from '../../../components/archive/DeleteIntegrationModal';
import NewDefaultAccountModal from '../../../components/archive/NewDefaultAccountModal';
import ConnectProPage from '../../../components/connect/ConnectProPage';
import {BankActions} from '../../../store/bank.action';
import {BankSelector} from '../../../store/bank.selector';
import PickAdditionalAccountModal from '../../bridge-api/components/PickAdditionalAccountModal';
import PickDefaultBankModal from '../../bridge-api/components/PickDefaultBankModal';
import PickIntegrationAccountModal from '../../bridge-api/components/PickIntegrationAccountModal';
import {BridgeActions} from '../../bridge-api/store/bridge-api.action';
import {getIntegrationInfo} from '../../bridge-api/utils/bridge-util';
import {BANK_TYPES, BankAccountStatus} from '../../bridge-api/utils/constants';
import {TransactionsActions} from '../store/transactions.action';
import {TransactionsSelector} from '../store/transactions.selector';
import {
    ADMIN_TOP_OFFSET,
    HIWAY_PRO_NAME,
    TOP_OFFSET,
    UNCATEGORIZED_FILTER_PARAM_NAME,
} from '../util/constants';

// TODO This should be provided by BE
const bankIconColors = [
    '#03A9F4',
    '#9575CD',
    '#A5D6A7',
    '#F48FB1',
    '#FFCC80',
    '#88f403',
];

export const TransactionAccounts = withJoyUI(({isAdminInterface, user}) => {
    const dispatch = useDispatch();
    const {t} = useTranslation('bank');

    const isMobileSize = useIsMobileSize();
    const integrations = useSelector(BankSelector.selectIntegrations);
    const archivedIntegrations = useSelector(BankSelector.selectIntegrationsWithArchived);
    const loggedInUser = useSelector(LoggedInUserSelectors.selectLoggedInUser);
    const totalOverview = useSelector(TransactionsSelector.selectTransactionTotalOverview);
    const bankAccounts = useSelector(BankSelector.createSelectBankAccounts());
    const categoryList = useSelector(TransactionsSelector.selectCategories);
    const sourcesList = useSelector(TransactionsSelector.selectSources);
    const isDeletingIntegration = useLoadingState(LoadingTypes.DELETE_BANK);
    const categorizationScreenInfo = useSelector(TransactionsSelector.selectCategorizationScreenInfo);

    const location = useSelector(selectRouterLocation);

    const isLoading = useSelector(LoadingSelectors.createLoadingSelectorByType(
        LoadingTypes.BANK_GET_INTEGRATIONS,
    ));

    const filterInitialState = {
        startDate: null,
        endDate: null,
        transactionTypes: [],
        isNotCategorized: location?.search === `?${UNCATEGORIZED_FILTER_PARAM_NAME}`,
        search: '',
    };

    const [hasBankSidebarOpen, setHasBankSidebarOpen] = useState(false);
    const [isLoaded, setIsLoaded] = useState(false);
    const [filter, setFilter] = useState(filterInitialState);

    const [hasHiwayPro, fullBankAccountList, bankAccountNumber, combinedTabIcons, hasArchived] = useMemo(() => {
        const combinedTabIcons = {};
        const accountList = bankAccounts.map((bankAccount, integrationIndex) => {
            const isHiway = bankAccount?.type === BANK_TYPES.hiway;

            const name = isHiway
                ? HIWAY_PRO_NAME
                : (bankAccount.bankName ?? ' Anonyme '); // TODO Check what should be in this case

            if (bankAccount?.accountId) {
                combinedTabIcons[bankAccount.accountId] = {
                    id: bankAccount?.integrationId,
                    name,
                    icon: isHiway
                        ? <HiText width="16px" height="14px" fill={userDefaultColor} />
                        : (
                            <Box
                                fontSize="md"
                                sx={{
                                    color: bankIconColors?.[integrationIndex],
                                }}
                            >
                                <FontAwesomeIcon
                                    icon={faBuildingColumns}
                                    fixedWidth
                                />
                            </Box>
                        ),
                };
            }

            return {
                ...bankAccount,
                color: bankIconColors?.[integrationIndex],
            };
        });

        const hasHiwayPro = accountList.some(bank => bank?.type === BANK_TYPES.hiway);

        // Check if there are archived integrations
        const hasArchived = archivedIntegrations?.length > 0 || totalOverview?.overviewPerAccount?.some(
            overview => overview?.status === BankAccountStatus.ARCHIVED,
        );

        // Add icons and ids for archvied
        if (hasArchived) {
            totalOverview.overviewPerAccount?.forEach((overview, index) => {
                const archivedIntegration = archivedIntegrations.find(integration => {
                    const {account} = getIntegrationInfo(integration);
                    return account?.id === overview?.id;
                });

                const isHiway = archivedIntegration?.type === BANK_TYPES.hiway;

                const name = isHiway
                    ? HIWAY_PRO_NAME
                    : (archivedIntegration?.bankName ?? overview?.name);

                if (!combinedTabIcons?.[overview?.id]) {
                    combinedTabIcons[overview?.id] = {
                        id: overview?.id,
                        name: name,
                        icon: isHiway
                            ? <HiText width="16px" height="14px" fill={userDefaultColor} />
                            : (
                                <Box
                                    fontSize="md"
                                    sx={{
                                        color: bankIconColors?.[index],
                                    }}
                                >
                                    <FontAwesomeIcon
                                        icon={faBuildingColumns}
                                        fixedWidth
                                    />
                                </Box>
                            ),
                    };
                }
            });
        }

        // Put hiway bank on first place if it is not
        if (hasHiwayPro && accountList[0]?.type !== BANK_TYPES.hiway) {
            const hiwayIndex = accountList.findIndex(account => account.type === BANK_TYPES.hiway);

            const oldFirstElement = {...accountList[0]};
            accountList.splice(0, 1, accountList[hiwayIndex]);
            accountList.splice(hiwayIndex, 1, oldFirstElement);
        }

        return [hasHiwayPro, accountList, accountList.length, combinedTabIcons, hasArchived];
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [bankAccounts, totalOverview]);

    const [tabValue, setTabValue] = useState(null);
    // Used to prevent multiple callings to get transactions on load
    const [isTabValueAuto, setTabValueAuto] = useState(false);
    const handleTabChange = newTab => {
        if (newTab === BANK_TYPES.new_bridge) {
            dispatch(BridgeActions.connectToBridge());
            return;
        }

        setFilter(filterInitialState);
        setTabValue(newTab);
        setTabValueAuto(false);
    };

    const topOffset = isAdminInterface ? ADMIN_TOP_OFFSET : TOP_OFFSET;

    const loadCategoriesList = () => {
        dispatch(TransactionsActions.getCategoryList());
        dispatch(TransactionsActions.getSourcesList());
    };

    // TODO Create more consistent way for this, there is probably no need for isLoaded variable
    // On delete/archive of integration we need to select new tab
    useEffect(() => {
        if (integrations?.length > 0) {
            setIsLoaded(false);
        } else if (archivedIntegrations?.length > 0) {
            setTabValue(BANK_TYPES.archived);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [integrations?.length, archivedIntegrations?.length]);

    useEffect(() => {
        const fullBankAccountListLength = fullBankAccountList?.length;

        if (!isLoaded) {
            if (fullBankAccountListLength > 1) {
                setTabValue(BANK_TYPES.combined);
            } else if (fullBankAccountListLength > 0) {
                setTabValue(fullBankAccountList[0].id);
            // } else {
            } else if (archivedIntegrations?.length > 0) {
                // to avoid tabValue=null, what will causes UX problems
                // also we'll show add button(s) above the table
                setTabValue(BANK_TYPES.archived);
            }
            setIsLoaded(true);
            setTabValueAuto(true);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [fullBankAccountList]);

    useEffect(() => {
        // HPD-4688 - Modify fetch categories logic on Admin bank interface
        if (
            ((!categoryList || categoryList?.length === 0)
            || (!sourcesList || sourcesList?.length === 0))
            && !isAdminInterface
        ) {
            loadCategoriesList();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [categoryList, integrations]);

    useEffect(() => {
        if (isAdminInterface) {
            loadCategoriesList();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [integrations]);

    useEffect(() => {
        // We need to call this in any case on reaching the page: whether there is a combined tab or not
        if (!categorizationScreenInfo) {
            dispatch(TransactionsActions.getCombinedTransactionList(isAdminInterface ? {userId: user?.id} : {}));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [integrations?.length]);

    useEffect(() => {
        if (loggedInUser?.hasBankAccess) {
            dispatch(BridgeActions.checkBridgeOnboarding());
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch]);

    useEffect(() => {
        if (isTabValueAuto) {
            setTabValueAuto(false);
        }
    }, [isTabValueAuto]);

    useEffect(() => {
        if (isDeletingIntegration) {
            setHasBankSidebarOpen(false);
            if (fullBankAccountList?.length > 0) {
                setTabValue(fullBankAccountList[0].id);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isDeletingIntegration]);

    // Logic regarding closing/archiving Hiway bank, open hiway tab
    useEffect(() => {
        if (location && location?.query?.closeAccountId && integrations?.length > 0) {
            const hiwayIntegration = integrations?.find(
                integration => integration?.id === location.query.closeAccountId,
            );


            if (hiwayIntegration?.id && !tabValue) {
                setTabValue(hiwayIntegration.id);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location, integrations]);

    const isAdmin = isUserAdmin(loggedInUser);
    const hasBridgeAccess = loggedInUser?.hasBridgeAccess;
    const isHiwayBankAddAllowed = !hasHiwayPro && !isAdmin;
    const isAbleToAddNewBank = useSelector(BankSelector.createSelectIsAbleToAddNewBank());

    const onClickAdd = () => {
        if (hasBridgeAccess) {
            dispatch(BridgeActions.connectToBridge());
        }
    };

    const onClickHiwayAdd = () => {
        dispatch(BankActions.createBankAccount());
    };

    if (bankAccountNumber === 0 && !hasArchived && !isLoading && !isAdminInterface) {
        return (
            <>
                <DeleteHiwayIntegrationModal />
                <ThemeProvider theme={theme}>
                    <ConnectProPage />
                </ThemeProvider>
            </>
        );
    }

    if (bankAccountNumber === 0 && isLoading) {
        return (
            <Box sx={{
                width: '100%',
                height: '500px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
            }}
            >
                <CircularProgress />
            </Box>
        );
    }

    return (
        <>
            <Fade
                timeout={300}
                in={!!categorizationScreenInfo}
            >
                <div>
                    {categorizationScreenInfo && (
                        <ThemeProvider theme={theme}>
                            <CategorisationPage
                                isAdminInterface={true}
                                user={user}
                            />
                        </ThemeProvider>
                    )}
                </div>
            </Fade>

            <Box sx={{
                opacity: categorizationScreenInfo ? '0' : '1',
                transition: 'opacity 0.3s ease-out',
            }}
            >
                <Box
                    sx={{
                        display: categorizationScreenInfo ? 'none' : 'block',
                    }}
                >
                    <Stack
                        direction="column"
                        gap={3}
                        sx={{
                            px: {mobile: 2, desktop: 0},
                            py: {mobile: 3, desktop: 0},
                        }}
                    >
                        {isMobileSize ? (
                            <FiltersMobileWrapper
                                filter={filter}
                                setFilter={setFilter}
                                pageTitle={t('pageHeader')}
                                searchPlaceholder={t('accountBalanceFilter.searchPlaceholder')}
                            />
                        ) : (
                            <Typography level="h1">{t('pageHeader')}</Typography>
                        )}

                        {(
                            (!isHiwayBankAddAllowed && !isAbleToAddNewBank)
                            || !!bankAccountNumber
                        )
                            ? null
                            : (
                                <Stack
                                    direction={isMobileSize ? 'column' : 'row'}
                                    alignItems="center"
                                    gap={isMobileSize ? 2 : 3}
                                >
                                    <AddButtons
                                        isHiwayBankAddAllowed={isHiwayBankAddAllowed}
                                        onClickHiwayAdd={onClickHiwayAdd}
                                        isBankAddAllowed={isAbleToAddNewBank}
                                        onClickAdd={onClickAdd}
                                    />
                                </Stack>
                            )}

                        {!!tabValue && (
                            <Section
                                headerStyles={{py: 2}}
                                bodyStyles={{px: isMobileSize ? 1 : 2}}
                                headerComponent={(
                                    <AccountsHeader
                                        tabValue={tabValue}
                                        handleTabChange={handleTabChange}
                                        setHasBankSidebarOpen={setHasBankSidebarOpen}
                                        hasCombined={bankAccountNumber > 1}
                                        fullBankAccountList={fullBankAccountList}
                                        combinedTotal={totalOverview?.totalAvailableBalance ?? 0}
                                        combinedUncategorized={totalOverview?.totalUncategorizedTransactions ?? 0}
                                        isAdminInterface={isAdminInterface}
                                        hasArchived={hasArchived}
                                        isTabValueAuto={isTabValueAuto}
                                        isHiwayBankAddAllowed={isHiwayBankAddAllowed}
                                        isBankAddAllowed={isAbleToAddNewBank}
                                        onClickHiwayAdd={onClickHiwayAdd}
                                        onClickAdd={onClickAdd}
                                    />
                                )}
                            >
                                <TabContext value={tabValue}>
                                    {bankAccountNumber > 0 && (
                                        <TabPanel
                                            value={BANK_TYPES.combined}
                                            sx={{p: 0}}
                                        >
                                            <CombinedTransactionList
                                                topOffset={topOffset}
                                                isAdminInterface={isAdminInterface}
                                                balance={totalOverview?.totalAvailableBalance ?? 0}
                                                isOpened={tabValue === BANK_TYPES.combined}
                                                user={user}
                                                combinedTabIcons={combinedTabIcons}
                                                isTabValueAuto={isTabValueAuto}
                                                filter={filter}
                                                setFilter={setFilter}
                                            />
                                        </TabPanel>
                                    )}
                                    {fullBankAccountList.map(bank => {
                                        if (bank.type === BANK_TYPES.hiway) {
                                            return (
                                                <HiwayTransactionList
                                                    key={bank.id}
                                                    topOffset={topOffset}
                                                    isAdminInterface={isAdminInterface}
                                                    bank={bank}
                                                    integrations={integrations}
                                                    isOpened={tabValue === BANK_TYPES.hiway || tabValue === bank.id}
                                                    hasBankSidebarOpen={hasBankSidebarOpen}
                                                    setTabValue={setTabValue}
                                                    isTabValueAuto={isTabValueAuto}
                                                    filter={filter}
                                                    setFilter={setFilter}
                                                />
                                            );
                                        }

                                        return (
                                            <TabPanel
                                                key={bank.id}
                                                value={bank.id}
                                                sx={{p: 0}}
                                            >
                                                <TransactionList
                                                    topOffset={topOffset}
                                                    isAdminInterface={isAdminInterface}
                                                    bank={bank}
                                                    isOpened={tabValue === bank.id}
                                                    hasBankSidebarOpen={hasBankSidebarOpen}
                                                    isTabValueAuto={isTabValueAuto}
                                                    filter={filter}
                                                    setFilter={setFilter}
                                                />
                                            </TabPanel>
                                        );
                                    })}
                                    {hasArchived && (
                                        <TabPanel
                                            value={BANK_TYPES.archived}
                                            sx={{p: 0}}
                                        >
                                            <CombinedTransactionList
                                                topOffset={topOffset}
                                                isAdminInterface={isAdminInterface}
                                                balance={totalOverview?.totalAvailableBalance ?? 0}
                                                isOpened={tabValue === BANK_TYPES.archived}
                                                user={user}
                                                combinedTabIcons={combinedTabIcons}
                                                isTabValueAuto={isTabValueAuto}
                                                isArchived={true}
                                                filter={filter}
                                                setFilter={setFilter}
                                            />
                                        </TabPanel>
                                    )}
                                </TabContext>

                                <BankSidebar
                                    onClose={() => setHasBankSidebarOpen(false)}
                                    isMobileSize={isMobileSize}
                                    integration={hasBankSidebarOpen}
                                />
                            </Section>
                        )}
                    </Stack>
                </Box>
            </Box>

            <PickAdditionalAccountModal />
            <PickIntegrationAccountModal />
            <PickDefaultBankModal />

            <CloseHiwayIntegrationUnavailableModal />
            <CloseHiwayIntegrationModal />
            <DeleteHiwayIntegrationModal />
            <NewDefaultAccountModal />
            <ArchiveIntegrationModal />
            <DeleteIntegrationModal />
        </>
    );
});

TransactionAccounts.propTypes = {
    isAdminInterface: PropTypes.bool,
    user: PropTypes.object,
};

TransactionAccounts.defaultProps = {
    isAdminInterface: false,
    user: null,
};
