import {createSelector} from 'reselect';
import {isUserAdmin} from '../../../utils/user-roles';
import {LoggedInUserSelectors} from '../../user/modules/logged-in-user/store/logged-in-user.selector';
import {TransactionsSelector} from '../modules/account-balance/store/transactions.selector';
import {BANK_ACCOUNT_MISSING, HIWAY_PRO_NAME} from '../modules/account-balance/util/constants';
import {BANK_TYPES, MAX_NUMBER_OF_BANKS} from '../modules/bridge-api/utils/constants';
import {BANK_ONBOARDING_STATUS, VerificationStatus} from '../utils/constants';

const selectActiveIntegrations = state => state.bank.activeIntegrations;
const selectArchivedIntegrations = state => state.bank.archivedIntegrations;
const selectIsBankMenuFullyActive = state => state.bank.isBankMenuFullyActive;
const selectQrCodeEvent = state => state.bank.qrCodeEvent;
const selectCapitalDepositError = state => state.bank.capitalDepositError;
const selectCapitalDeposit = state => state.bank.capitalDeposit;

const createSelectBankIntegrations = type => createSelector(
    [selectActiveIntegrations],
    integrations => {
        // TODO Bridge
        return integrations.find(integration => integration.type === type) ?? null;
    },
);

const createSelectBankAccountHolders = () => createSelector(
    [selectActiveIntegrations],
    bankIntegrationDataList => {
        if (!bankIntegrationDataList.length || bankIntegrationDataList.length === 0) {
            return null;
        }
        const bankIntegrationData = bankIntegrationDataList[0];

        if (!bankIntegrationData?.bankAccountHolders || bankIntegrationData?.bankAccountHolders.length === 0) {
            return;
        }

        // Get correct bankAccountHolders data
        return bankIntegrationData.bankAccountHolders.length === 1
            ? bankIntegrationData.bankAccountHolders[0]
            : bankIntegrationData.bankAccountHolders.find(
                integration => integration?.type === 'COMPANY',
            );
    },
);

const createSelectCanAccessBankDashboard = () => createSelector(
    [selectActiveIntegrations],
    bankIntegrationDataList => {
        if (!bankIntegrationDataList
            || !bankIntegrationDataList.length
            || bankIntegrationDataList.length === 0) {
            return false;
        }
        let returnValue = false;
        bankIntegrationDataList.forEach(bankIntegrationData => {
            if (!bankIntegrationData?.bankAccountHolders || bankIntegrationData?.bankAccountHolders.length === 0) {
                return false;
            }

            if (!bankIntegrationData?.isAuthenticated) {
                return false;
            }

            // Get correct bankAccountHolders data
            const bankAccountHolders = bankIntegrationData.bankAccountHolders.length === 1
                ? bankIntegrationData.bankAccountHolders[0]
                : bankIntegrationData.bankAccountHolders.find(
                    integration => integration?.type === 'COMPANY',
                );

            if (bankAccountHolders?.bankAccounts?.[0]
                && bankAccountHolders.onboardingStatus === BANK_ONBOARDING_STATUS.FINALIZED
                && bankAccountHolders.verificationStatus === VerificationStatus.VERIFIED) {
                returnValue = true;
            }
        });

        return returnValue;
    },
);

const createSelectDefaultIntegration = () => createSelector(
    [selectActiveIntegrations],
    integrations => {
        return integrations.find(integration => {
            const bankAccountHolder = integration?.bankAccountHolders.find(holder => holder.type === 'COMPANY');
            const bankAccount = bankAccountHolder?.bankAccounts?.[0];
            return bankAccount?.isDefault;
        });
    },
);

const createSelectIsAbleToAddNewBank = () => createSelector(
    [LoggedInUserSelectors.selectLoggedInUser, selectActiveIntegrations], (loggedInUser, integrations) => {
        const isAdmin = isUserAdmin(loggedInUser);

        if (isAdmin) {
            // Admin cannot add a bank
            return false;
        }

        const hasBridgeAccess = loggedInUser?.hasBridgeAccess;
        const hasHiwayPro = integrations.some(bank => bank?.type === BANK_TYPES.hiway);
        const maxAllowedBanks = hasHiwayPro ? MAX_NUMBER_OF_BANKS + 1 : MAX_NUMBER_OF_BANKS;

        return hasBridgeAccess && (integrations.length < maxAllowedBanks);
    },
);

const createSelectBankAccounts = () => createSelector(
    [selectActiveIntegrations, TransactionsSelector.selectTransactionTotalOverview],
    (integrations, totalOverview) => {
        const accountList = integrations.map(integration => {
            const isHiwayBank = integration?.type === BANK_TYPES.hiway;

            const bankAccountHolder = integration?.bankAccountHolders.find(holder => holder.type === 'COMPANY');
            const bankAccount = bankAccountHolder?.bankAccounts?.[0];
            const accountId = bankAccount?.id ?? BANK_ACCOUNT_MISSING;

            let amount = 0;
            let uncategorized = 0;
            const name = isHiwayBank
                ? HIWAY_PRO_NAME
                : (integration.bankName ?? ' Anonyme '); // TODO Check what should be in this case

            if (totalOverview?.overviewPerAccount) {
                const overview = totalOverview.overviewPerAccount.find(
                    overview => overview.id === accountId,
                );

                if (overview) {
                    amount = overview.availableBalance;
                    uncategorized = overview.numberOfUncategorizedTransactions;
                } else if (bankAccount) {
                    amount = bankAccount?.availableBalance;
                }
            }

            const shouldAskForIntegrationAccount = integration?.bankAccountHolders?.length === 0
                && integration?.integrationAccounts?.length > 1;

            const holderVerificationStatus = bankAccountHolder?.verificationStatus;

            return {
                ...integration,
                name,
                integrationId: bankAccountHolder?.bankIntegrationId,
                accountId,
                amount,
                uncategorized,
                iban: bankAccount?.iban,
                bic: bankAccount?.bic,
                lastSyncAt: bankAccountHolder?.lastSyncAt,
                shouldAskForIntegrationAccount,
                isHiwayBank,
                holderVerificationStatus,
            };
        });

        return accountList ?? [];
    },
);

export const BankSelector = {
    selectIntegrations: selectActiveIntegrations, // TODO Rename selectors throughout components
    selectIntegrationsWithArchived: selectArchivedIntegrations, // TODO Rename selectors throughout components
    createSelectBankIntegrations,
    selectIsBankMenuFullyActive,
    selectQrCodeEvent,
    createSelectBankAccountHolders,
    createSelectCanAccessBankDashboard,
    selectCapitalDepositError,
    selectCapitalDeposit,
    createSelectDefaultIntegration,
    createSelectIsAbleToAddNewBank,
    createSelectBankAccounts,
};
