import i18next from 'i18next';
import {COMPANY_STATUSES} from '../../../company/setupCompany/setupCompany.constants';

const t = i18next.t.bind(i18next);
/**
 * @typedef {Object} PersonalInformationDTO
 * @property {String} title
 * @property {String} first_name
 * @property {String} last_name
 * @property {String} date_of_birth
 * @property {String} country_of_birth
 * @property {String} place_of_birth
 * @property {String} regional_department_of_birth
 * @property {String} nationality
 * @property {String} social_security_number
 */

/**
 * @typedef {Object} PersonalInformationData
 * @property {String} title
 * @property {String} firstName
 * @property {String} lastName
 * @property {String} dateOfBirth
 * @property {String} countryOfBirth
 * @property {String} placeOfBirth
 * @property {String} regionalDepartmentOfBirth
 * @property {String} nationality
 * @property {String} socialSecurityNumber
 */

/**
 * @typedef {Object} NonConvictionDeclarationDTO
 * @property {String} fathers_first_name
 * @property {String} fathers_last_name
 * @property {String} mothers_first_name
 * @property {String} mothers_last_name
 */

/**
 * @typedef {Object} NonConvictionDeclarationData
 * @property {String} fathersFirstName
 * @property {String} fathersLastName
 * @property {String} mothersFirstName
 * @property {String} mothersLastName
 */

/**
 * @typedef {Object} CompanyInformationDTO
 * @property {String} amount_of_share_capital_units
 * @property {String} bank
 * @property {String} name
 * @property {String} professional_activity
 * @property {String} bank_address
 * @property {String} activity_start_date
 */

/**
 * @typedef {Object} CompanyInformationData
 * @property {String} amountOfShareCapital
 * @property {String} bank
 * @property {String} companyName
 * @property {String} professionalActivity
 * @property {String} bankAddress
 */

/**
 * @typedef {Object} EnterpriseInformationDTO
 * @property {String} activity_start_date
 */

/**
 * @typedef {Object} EnterpriseInformationData
 * @property {String} activityStartDate
 */

/**
 * @typedef {Object} DocumentsDTO
 * @property {String} id_document_type
 * @property {Boolean} hosted_by_third_party
 * @property {String} nin_document_type
 * @property {String} host_id_document_type
 */

/**
 * @typedef {Object} DocumentsData
 * @property {String} identificationDocumentType
 * @property {Boolean} isUserHostedByThirdParty
 * @property {String} insuranceDocumentType
 * @property {String} hostIdentificationDocumentType
 */

export const fromCompanyDTO = companyDTO => ({
    id: companyDTO.id,
    name: companyDTO.name || '',
    // TODO USER_DEACTIVATED is not a real status, check if possible to remove
    status: !companyDTO.freelancer_active ? COMPANY_STATUSES.USER_DEACTIVATED : companyDTO.status,
    legalForm: companyDTO.legal_form || '',
    taxSystem: companyDTO.enterprise_info?.tax_system || '',
    payInfoStatus: companyDTO.pay_info?.status || '',
    userId: companyDTO.freelancer_id,
    userFullName: `${companyDTO.freelancer_first_name} ${companyDTO.freelancer_last_name}`,
    coachFullName: companyDTO.coach_first_name || companyDTO.coach_last_name
        ? `${companyDTO.coach_first_name} ${companyDTO?.coach_last_name}`
        : null,
    coachId: companyDTO.coach_id,
    freelancerActive: companyDTO.freelancer_active,
    type: companyDTO.company_type,
    trainingEndDate: companyDTO.training_info?.end_date,
    trainingStartDate: companyDTO.training_info?.start_date,
    trainingStatus: companyDTO.training_info?.status,
    trainingInvoiceId: companyDTO.training_invoice_doc_id,
    trainingHideAlertUntil: companyDTO.training_info?.hide_training_alert_notification_until,
    hasHiwayBank: !!companyDTO?.company_info?.hiway_bank_info,
});

/**
 * Returns transformed non-conviction declaration data object
 * @param {CompanyInformationDTO} companyInformationDTO What we get from API
 * @returns {EnterpriseInformationData} Our data
 */
export const fromEnterpriseInformationDTO = companyInformationDTO => ({
    activityStartDate: companyInformationDTO?.enterprise_info?.activity_start_date,

    headquarters_city: companyInformationDTO?.enterprise_info?.headquarters_city,
    headquarters_country: companyInformationDTO?.enterprise_info?.headquarters_country,
    headquarters_street: companyInformationDTO?.enterprise_info?.headquarters_street,
    headquarters_street_number: companyInformationDTO?.enterprise_info?.headquarters_street_number,
    headquarters_zip: companyInformationDTO?.enterprise_info?.headquarters_zip,

    note: companyInformationDTO?.enterprise_info?.note,
    siren: companyInformationDTO?.enterprise_info?.siren,
    siret: companyInformationDTO?.enterprise_info?.siret,
    apeNafCode: companyInformationDTO?.enterprise_info?.ape_naf_code,
    vat: companyInformationDTO?.enterprise_info?.vat,
    tvaFrequencyPayment: companyInformationDTO?.enterprise_info?.tva_frequency_payment,
    taxSystem: companyInformationDTO?.enterprise_info?.tax_system,
    docType: companyInformationDTO?.enterprise_info?.doc_type,
    useCustomCompanyAddress: companyInformationDTO?.enterprise_info?.use_custom_company_address,
    registrationType: companyInformationDTO?.enterprise_info?.registration_type,
});

export const fromSingleCompanyDTO = companyDTO => ({
    ...fromCompanyDTO(companyDTO),
    personalInformation: fromPersonalInformationDTO(companyDTO?.personal_info),
    nonConvictionDeclaration: fromNonConvictionDeclarationDTO(companyDTO?.non_conviction),
    companyInformation: fromCompanyInformationDTO(companyDTO?.company_info),
    enterpriseInformation: fromEnterpriseInformationDTO(companyDTO),
    personalDocuments: fromDocumentsDTO(companyDTO?.personal_documents ?? {}),
    registrationInfo: companyDTO?.registration_info,
    payInfo: fromPayInfoDTO(companyDTO?.pay_info),
    additionalInfo: companyDTO?.freelancers_additional_info,
});

export const transformCompanies = companyDTOs => {
    const companies = {};

    companyDTOs.forEach(companyDTO => {
        companies[companyDTO.id] = {
            ...fromCompanyDTO(companyDTO),
            activityStartDate: companyDTO.enterprise_info?.activity_start_date || null,
        };
    });

    return companies;
};

/**
 *
 * Returns transformed personal information data object
 * @param {PersonalInformationDTO} personalInformationDTO What we get from API
 * @returns {PersonalInformationData} Our data
 */
export const fromPersonalInformationDTO = personalInformationDTO => {
    if (!personalInformationDTO || !Object.keys(personalInformationDTO).length) {
        return {};
    }

    return {
        title: personalInformationDTO?.title,
        firstName: personalInformationDTO?.first_name,
        lastName: personalInformationDTO?.last_name,
        dateOfBirth: personalInformationDTO?.date_of_birth,
        countryOfBirth: personalInformationDTO?.country_of_birth,
        placeOfBirth: personalInformationDTO?.place_of_birth,
        regionalDepartmentOfBirth: personalInformationDTO?.regional_department_of_birth,
        nationality: personalInformationDTO?.nationality,
        socialSecurityNumber: personalInformationDTO?.social_security_number,
        postalCode: personalInformationDTO?.zip_code,
    };
};

/**
 * Returns transformed personal information data object
 * @param {PersonalInformationData} personalInformationData Our data
 * @returns {PersonalInformationDTO} What we send to API
 */
export const toPersonalInformationDTO = personalInformationData => ({
    personal_info: {
        title: personalInformationData.title,
        first_name: personalInformationData.firstName,
        last_name: personalInformationData.lastName,
        date_of_birth: personalInformationData.dateOfBirth,
        country_of_birth: personalInformationData.countryOfBirth,
        place_of_birth: personalInformationData.placeOfBirth,
        regional_department_of_birth: personalInformationData.regionalDepartmentOfBirth,
        nationality: personalInformationData.nationality,
        social_security_number: personalInformationData.socialSecurityNumber,
        zip_code: personalInformationData.postalCode,
    },
});


/**
 * Returns transformed non-conviction declaration data object
 * @param {NonConvictionDeclarationDTO} nonConvictionDeclarationDTO What we get from API
 * @returns {NonConvictionDeclarationData} Our data
 */
export const fromNonConvictionDeclarationDTO = nonConvictionDeclarationDTO => {
    if (!nonConvictionDeclarationDTO || !Object.keys(nonConvictionDeclarationDTO).length) {
        return {};
    }

    return {
        fathersFirstName: nonConvictionDeclarationDTO?.fathers_first_name,
        fathersLastName: nonConvictionDeclarationDTO?.fathers_last_name,
        mothersFirstName: nonConvictionDeclarationDTO?.mothers_first_name,
        mothersLastName: nonConvictionDeclarationDTO?.mothers_last_name,
    };
};

/**
 * Returns transformed non-conviction declaration data object
 * @param {NonConvictionDeclarationData} nonConvictionDeclarationData Our data
 * @returns {NonConvictionDeclarationDTO} What we send to API
 */
export const toNonConvictionDeclarationDTO = nonConvictionDeclarationData => ({
    non_conviction: {
        fathers_first_name: nonConvictionDeclarationData.fathersFirstName,
        fathers_last_name: nonConvictionDeclarationData.fathersLastName,
        mothers_first_name: nonConvictionDeclarationData.mothersFirstName,
        mothers_last_name: nonConvictionDeclarationData.mothersLastName,
    },
});

/**
 * Returns transformed non-conviction declaration data object
 * @param {CompanyInformationDTO} companyInformationDTO What we get from API
 * @returns {CompanyInformationData} Our data
 */
export const fromCompanyInformationDTO = companyInformationDTO => {
    if (!companyInformationDTO || !Object.keys(companyInformationDTO).length) {
        return {};
    }

    return {
        amountOfShareCapital: companyInformationDTO?.amount_of_share_capital_units,
        bank: companyInformationDTO?.bank,
        companyName: companyInformationDTO?.name,
        professionalActivity: companyInformationDTO?.professional_activity,
        iban: companyInformationDTO?.iban,
        bic: companyInformationDTO?.bic,
        bankAddress: companyInformationDTO?.bank_address,
        hiwayBankInfo: companyInformationDTO?.hiway_bank_info,
    };
};

/**
 * Returns transformed non-conviction declaration data object
 * @param {CompanyInformationData} companyInformationData Our data
 * @returns {CompanyInformationDTO} What we send to API
 */
export const toCompanyInformationDTO = companyInformationData => ({
    company_info: {
        amount_of_share_capital_units: companyInformationData.amountOfShareCapital || undefined,
        bank: companyInformationData.bank || undefined,
        name: companyInformationData.companyName,
        professional_activity: companyInformationData.professionalActivity,
        bank_address: companyInformationData.bankAddress || undefined,
    },
});

/**
 * Returns transformed non-conviction declaration data object
 * @param {CompanyInformationData} companyInformationData Our data
 * @returns {CompanyInformationDTO} What we send to API
 */
export const toCompanyInformationIbanBicDTO = companyInformationData => {
    const companyInfo = {};
    if (companyInformationData.iban) {
        companyInfo.iban = companyInformationData.iban;
    }
    if (companyInformationData.bic) {
        companyInfo.bic = companyInformationData.bic;
    }

    return {
        company_info: companyInfo,
    };
};

/**
 * Returns transformed non-conviction declaration data object
 * @param {DocumentsDTO} documentsDTO What we get from API
 * @returns {DocumentsData} Our data
 */
export const fromDocumentsDTO = documentsDTO => {
    if (!documentsDTO || !Object.keys(documentsDTO).length) {
        return {};
    }

    return {
        identificationDocumentType: documentsDTO?.id_document_type,
        isUserHostedByThirdParty: documentsDTO?.hosted_by_third_party,
        insuranceDocumentType: documentsDTO?.nin_document_type,
        hostIdentificationDocumentType: documentsDTO?.host_id_document_type,
    };
};

/**
 * Returns transformed pay data object
 * @param {DocumentsDTO} payDTO What we get from API
 */
export const fromPayInfoDTO = payDTO => {
    if (!payDTO || !Object.keys(payDTO).length) {
        return {};
    }

    return {
        status: payDTO.status,
    };
};

/**
 * Returns transformed non-conviction declaration data object
 * @param {DocumentsData} documentsData Our data
 * @returns {DocumentsDTO} What we send to API
 */
export const toDocumentsDTO = documentsData => ({
    personal_documents: {
        id_document_type: documentsData.identificationDocumentType,
        hosted_by_third_party: documentsData.isUserHostedByThirdParty,
        nin_document_type: documentsData.insuranceDocumentType,
        host_id_document_type: documentsData.hostIdentificationDocumentType,
    },
});

const originalFilenameTypes = [
    'OTHER',
    'PAYSLIP',
    'BALANCE_SHEET',
    'BANK_ACCOUNT_STATEMENT',
    'PV_AGO',
    'ANNUAL_ACCOUNT_BALANCE_SHEET',
    'ANNUAL_ACCOUNT_PRIVACY_STATEMENT',
    'POWER_OF_ATTORNEY_SUBMISSION_OF_ACCOUNTS',
];

export const fromCompanyDocumentDTO = document => ({
    companyId: document.company_id,
    freelancerId: document.freelancerId || document.user_id,
    category: document.doc_category,
    subType: document.doc_sub_type,
    type: document.doc_type,
    id: document.id,
    fileName: document.original_filename,
    status: document.doc_status,
    uploaderId: document.uploader_id,
    dateCreated: document.created_at,
    // HPD-6245 Added display name to solve specific task related to liasse fiscal document
    docName: document.display_name
        ? document.display_name
        : originalFilenameTypes.includes(document.doc_type)
            ? document.original_filename
            : t(`documents:documentsDatabaseNames.${document.doc_type}`),
});

export const toCompanyDocumentDTO = document => ({
    doc_sub_type: document.subType,
    original_filename: document.fileName,
});

export const transformPersonalCompanyDocuments = documentDTOs => {
    if (!documentDTOs?.length) {
        return {};
    }

    const documents = {
        identificationDocument: null,
        residenceCertificationDocument: null,
        hostIdentificationDocument: null,
        hostCertificationDocument: null,
        insuranceDocument: null,
    };

    documentDTOs.forEach(documentDTO => {
        const document = fromCompanyDocumentDTO(documentDTO);

        switch (document.type) {
            case 'ID_DOC':
                documents.identificationDocument = document;

                break;

            case 'NIN_DOC':
                documents.insuranceDocument = document;

                break;

            case 'RESIDENCE_CERT':
                documents.residenceCertificationDocument = document;

                break;

            case 'RESIDENCE_CERT_HOST':
                documents.hostCertificationDocument = document;

                break;

            case 'RESIDENCE_CERT_HOST_ID':
                documents.hostIdentificationDocument = document;

                break;
        }
    });

    return documents;
};

export const transformTrainingCompanyDocuments = (documentDTOs, params) => {
    const {freelancerId} = params;

    if (!documentDTOs) {
        return {};
    }

    return documentDTOs.map(documentDTO => {
        return fromCompanyDocumentDTO({...documentDTO, freelancerId});
    });
};

export const transformCompanyDocuments = (documentDTOs, params) => {
    const {freelancerId} = params;

    if (!documentDTOs) {
        return {};
    }

    const documents = {};

    documentDTOs.forEach(documentDTO => {
        documents[documentDTO.id] = fromCompanyDocumentDTO({...documentDTO, freelancerId});
    });

    return documents;
};
