export const ModalsKeys = {
    ACCOUNT_TRANSFER_CREDENTIALS: 'ACCOUNT_TRANSFER_CREDENTIALS',
    UPLOAD_SIGNATURE: 'UPLOAD_SIGNATURE',
    DOCUMENT_SIGNING_STEP: 'DOCUMENT_SIGNING_STEP',
    REMINDER_EMAIL: 'REMINDER_EMAIL',
    REGISTRATION_STATUS_MODAL: 'REGISTRATION_STATUS_MODAL',
    INCOME_TAX_MODAL: 'INCOME_TAX_MODAL',
    TRAINING_DOSSIER_MODAL: 'TRAINING_DOSSIER_MODAL',
    TRAINING_DOSSIER_RESET_DATE: 'TRAINING_DOSSIER_RESET_DATE',
    FUNDING_APPROVAL: 'FUNDING_APPROVAL',
    FUNDING_APPROVAL_DATE_ALERT: 'FUNDING_APPROVAL_DATE_ALERT',
    BACKOFFICE_EDIT_DATE: 'BACKOFFICE_EDIT_DATE',
    ACCOUNT_BALANCE_SUPPORT_DOCUMENT: 'ACCOUNT_BALANCE_SUPPORT_DOCUMENT',
    BUSINESS_ALLOWANCE_DISABLED: 'BUSINESS_ALLOWANCE_DISABLED',
    TRAINING_DISABLED: 'TRAINING_DISABLED',
    BANK_CARD_MODAL: 'BANK_CARD_MODAL',
    BANK_NO_FILE: 'BANK_NO_FILE',
    INSTANT_TRANSFER: 'INSTANT_TRANSFER',
    CALCULATION_REQUEST_SEND: 'CALCULATION_REQUEST_SEND',
    CREATE_INVOICE: 'CREATE_INVOICE',
    CREATE_JOB_FORMS: 'CREATE_JOB_FORMS',
    ATTACH_DOCUMENTS: 'ATTACH_DOCUMENTS',
    UPLOAD_DOCUMENTS: 'UPLOAD_DOCUMENTS',
    INVOICE_DRAFT_CONFIRMATION_MODAL: 'INVOICE_DRAFT_CONFIRMATION_MODAL',
    INVOICE_FINALIZATION_MODAL: 'INVOICE_DRAFT_CONFIRMATION_MODAL',
    INVOICE_CANCEL_MODAL: 'INVOICE_CANCEL_MODAL',
    INVOICE_CANCEL_EXTERNAL_MODAL: 'INVOICE_CANCEL_EXTERNAL_MODAL',
    INVOICE_DUE_DATE_MODAL: 'INVOICE_DUE_DATE_MODAL',
    INVOICE_CREATE_CANCEL_MODAL: 'INVOICE_CREATE_CANCEL_MODAL',
    INVOICE_SEND_REMINDER_MODAL: 'INVOICE_SEND_REMINDER_MODAL',
    INVOICE_SEND_REMINDER_ERROR_MODAL: 'INVOICE_SEND_REMINDER_ERROR_MODAL',
    INVOICE_SEND_MODAL: 'INVOICE_SEND_MODAL',
    DELETE_SERVICE_MODAL: 'DELETE_SERVICE_MODAL',
    DELETE_CLIENT_MODAL: 'DELETE_CLIENT_MODAL',
    CLOSE_EXTERNAL_INVOICE_SIDEBAR: 'CLOSE_EXTERNAL_INVOICE_SIDEBAR',
    NO_ACCOUNT_MODAL: 'NO_ACCOUNT_MODAL',
    PICK_ADDITIONAL_ACCOUNT_MODAL: 'PICK_ADDITIONAL_ACCOUNT_MODAL',
    PICK_INTEGRATION_ACCOUNT_MODAL: 'PICK_INTEGRATION_ACCOUNT_MODAL',
    UNDERSTANDING_INFO_MODAL: 'UNDERSTANDING_INFO_MODAL',
    TRANSACTION_ACCOUNTS_MODAL: 'TRANSACTION_ACCOUNTS_MODAL',
    PICK_DEFAULT_BANK_MODAL: 'PICK_DEFAULT_BANK_MODAL',
    DELETE_INTEGRATION_MODAL: 'DELETE_INTEGRATION_MODAL',
    ARCHIVE_INTEGRATION_MODAL: 'ARCHIVE_INTEGRATION_MODAL',
    CLOSE_HIWAY_INTEGRATION_UNAVAILABLE_MODAL: 'CLOSE_HIWAY_INTEGRATION_UNAVAILABLE_MODAL',
    CLOSE_HIWAY_INTEGRATION_MODAL: 'CLOSE_HIWAY_INTEGRATION_MODAL',
    NEW_DEFAULT_ACCOUNT_MODAL: 'NEW_DEFAULT_ACCOUNT_MODAL',
    CONFIRM_HIWAY_DELETE_MODAL: 'CONFIRM_HIWAY_DELETE_MODAL',
    UPLOAD_REFUSED_DOCUMENT: 'UPLOAD_REFUSED_DOCUMENT',
    DOCUMENTATION_SEARCH_RESULTS: 'DOCUMENTATION_SEARCH_RESULTS',
    ARIA_SIMULATOR: 'ARIA_SIMULATOR',
    ARIA_LATER: 'ARIA_LATER',
    ARIA_DOCUMENTS: 'ARIA_DOCUMENTS',
    ARIA_VERIFICATION_MODAL: 'ARIA_VERIFICATION_MODAL',

    ACCOUNT_ARCHIVING_MODAL5: 'ACCOUNT_ARCHIVING_MODAL5',
    ACCOUNT_ARCHIVING_MODAL7: 'ACCOUNT_ARCHIVING_MODAL7',

    INSURANCE_SKIP_MODAL: 'INSURANCE_SKIP_MODAL',
    INSURANCE_SUBSCRIBE_MODAL: 'INSURANCE_SUBSCRIBE_MODAL',
    INSURANCE_SIGN_MODAL: 'INSURANCE_SIGN_MODAL',
    RENEW_CERTIFICATE_MODAL: 'RENEW_CERTIFICATE_MODAL',

    // Categorization
    CATEGORIZATION_DEACTIVATE_SUBSCRIPTION: 'CATEGORIZATION_DEACTIVATE_SUBSCRIPTION',
    CATEGORIZATION_PROOF_OF_DOCUMENTS: 'CATEGORIZATION_PROOF_OF_DOCUMENTS',
    CATEGORIZATION_CHANGE_SUBSCRIPTION: 'CATEGORIZATION_CHANGE_SUBSCRIPTION',
    CATEGORISATION_CLOSE_EXTERNAL_INVOICE: 'CATEGORISATION_CLOSE_EXTERNAL_INVOICE',
    CATEGORISATION_INVALID_ARTICLES: 'CATEGORISATION_INVALID_ARTICLES',

    /* -----------
     * Formalities
     * -----------
     */

    /* Deposit of Accounts / Annual accounts */
    DEPOSIT_OF_ACCOUNTS_PAYMENT: 'DEPOSIT_OF_ACCOUNTS_PAYMENT',
    DEPOSIT_OF_ACCOUNTS_MANUAL_MODE: 'DEPOSIT_OF_ACCOUNTS_MANUAL_MODE',
    DEPOSIT_OF_ACCOUNTS_GENERATE_DOCUMENTS: 'DEPOSIT_OF_ACCOUNTS_GENERATE_DOCUMENTS',
    DEPOSIT_OF_ACCOUNTS_REGENERATE_DOCUMENTS: 'DEPOSIT_OF_ACCOUNTS_REGENERATE_DOCUMENTS',

    // Dashboard
    INCOME_TAX_SIMULATOR: 'INCOME_TAX_SIMULATOR',
    INCOME_TAX_SIMULATOR_UNSAVED_CHANGES: 'INCOME_TAX_SIMULATOR_UNSAVED_CHANGES',

    /* VAT Declaration */
    SEND_VAT_DECLARATIONS: 'SEND_VAT_DECLARATIONS',
    PROCESS_SINGLE_VAT_DECLARATION: 'PROCESS_SINGLE_VAT_DECLARATION',
    PROCESS_MULTIPLE_VAT_DECLARATION: 'PROCESS_MULTIPLE_VAT_DECLARATION',
    VAT_DECLARATION_SWITCH_TO_MANUAL_MODE: 'VAT_DECLARATION_SWITCH_TO_MANUAL_MODE',
    VAT_DECLARATION_RESEND_DECLARATION: 'VAT_DECLARATION_RESEND_DECLARATION',
    VAT_DECLARATION_VALIDATE_CONFIRMATION: 'VAT_DECLARATION_VALIDATE_CONFIRMATION',
    VAT_DECLARATION_ABANDON_CHANGES: 'VAT_DECLARATION_ABANDON_CHANGES',
};

export const V3ModalsKeys = [
    ModalsKeys.CLOSE_HIWAY_INTEGRATION_UNAVAILABLE_MODAL,
    ModalsKeys.TRANSACTION_ACCOUNTS_MODAL,
    ModalsKeys.PICK_ADDITIONAL_ACCOUNT_MODAL,
    ModalsKeys.PICK_INTEGRATION_ACCOUNT_MODAL,
    ModalsKeys.PICK_DEFAULT_BANK_MODAL,
    ModalsKeys.CLOSE_HIWAY_INTEGRATION_MODAL,
    ModalsKeys.CONFIRM_HIWAY_DELETE_MODAL,
    ModalsKeys.NEW_DEFAULT_ACCOUNT_MODAL,
    ModalsKeys.ARCHIVE_INTEGRATION_MODAL,

    /* Dashboard V3 */
    ModalsKeys.INCOME_TAX_SIMULATOR,
    ModalsKeys.INCOME_TAX_SIMULATOR_UNSAVED_CHANGES,

    /* VAT Declaration */
    ModalsKeys.SEND_VAT_DECLARATIONS,
    ModalsKeys.PROCESS_SINGLE_VAT_DECLARATION,
    ModalsKeys.PROCESS_MULTIPLE_VAT_DECLARATION,
    ModalsKeys.VAT_DECLARATION_SWITCH_TO_MANUAL_MODE,
    ModalsKeys.VAT_DECLARATION_RESEND_DECLARATION,
    ModalsKeys.VAT_DECLARATION_VALIDATE_CONFIRMATION,
    ModalsKeys.VAT_DECLARATION_ABANDON_CHANGES,
];
