import {Box, Stack} from '@mui/joy';
import {Suspense, useMemo} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {Outlet} from 'react-router-dom';
import {BackButton} from './components/BackButton';
import {CompanyAndPeriod} from './components/CompanyAndPeriod';
import {Header} from './components/Header';
import JoyUIThemeProvider from '../../components/joy-ui/joyui-theme-provider/JoyUiThemeProvider';
import {LoadingSkeleton, LoadingSkeletonProvider} from '../../components/joy-ui/loading-skeleton/LoadingSkeleton';
import {SuspenseContainer} from '../../components/suspense/Suspense';
import {UiActions} from '../../features/ui/store/ui.action';
import {ModalsKeys} from '../../features/ui/utils/constants';
import {AbandonChangesModal} from '../../features/vat-declaration/components/manual-mode/AbandonChangesModal';
import {ManualModeSwitch} from '../../features/vat-declaration/components/manual-mode/ManualModeSwitch';
import {Status} from '../../features/vat-declaration/components/Status';
import {VatDeclarationStatus} from '../../features/vat-declaration/constants';
import {VATDeclarationActions} from '../../features/vat-declaration/store/vat-declaration.action';
import {VatDeclarationSelectors} from '../../features/vat-declaration/store/vat-declaration.selector';
import {getDeclarationPeriodString} from '../../features/vat-declaration/utils';
import {importLocaleBundle} from '../../lib/i18next';
import {routerActions} from '../../lib/router/connected-router-saga/connected-router.actions';
import {RoutePaths} from '../../lib/router/route-paths';

importLocaleBundle('vatDeclaration');

export const VatDeclarationResultLayout = () => {
    const declaration = useSelector(VatDeclarationSelectors.selectVATDeclaration);
    const isEditing = useSelector(VatDeclarationSelectors.selectIsEditingDeclaration);
    const dispatch = useDispatch();

    const onBackClick = () => {
        dispatch(
            isEditing
                ? UiActions.setActiveModal(ModalsKeys.VAT_DECLARATION_ABANDON_CHANGES, true)
                : routerActions.back(),
        );
    };

    const isTodo = declaration?.status === VatDeclarationStatus.TODO;
    const isSubmitted = declaration?.status === VatDeclarationStatus.SUBMITTED;
    const isValidated = declaration?.status === VatDeclarationStatus.VALIDATED;
    const shouldShowManualSwitch = declaration?.isManual
        || (!isTodo && !isSubmitted && !isValidated && !declaration?.childDeclarationId);

    const declarationPeriod = useMemo(() => getDeclarationPeriodString(declaration), [declaration]);

    const handleConfirmAbandon = () => {
        dispatch(VATDeclarationActions.setIsEditingDeclaration(false));
        dispatch(UiActions.setActiveModal(ModalsKeys.VAT_DECLARATION_ABANDON_CHANGES, false));
    };

    return (
        <JoyUIThemeProvider>
            <Box sx={{bgcolor: 'background.body', minHeight: '100vh'}}>
                <LoadingSkeletonProvider isLoading={!declaration} pulseBg="var(--joy-palette-common-black)">
                    <Header>
                        <BackButton onBackClick={onBackClick} fallbackRoute={RoutePaths.VAT_DECLARATION} />
                        <CompanyAndPeriod
                            isLoading={!declaration}
                            companyId={declaration?.companyId}
                            freelancerId={declaration?.freelancer.id}
                            companyName={declaration?.company.name}
                            declarationPeriod={declarationPeriod}
                        />
                        <LoadingSkeleton placeholder="--------">
                            <Stack direction="row" spacing={2}>
                                {shouldShowManualSwitch && <ManualModeSwitch />}
                                <Status status={declaration?.status} />
                            </Stack>
                        </LoadingSkeleton>
                    </Header>
                </LoadingSkeletonProvider>
                <Box sx={{color: 'primary.main'}}>
                    <Suspense fallback={<SuspenseContainer />}>
                        <Outlet />
                        <AbandonChangesModal onConfirm={handleConfirmAbandon} />
                    </Suspense>
                </Box>
            </Box>
        </JoyUIThemeProvider>
    );
};
